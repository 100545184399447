header {
    position: relative;
    z-index: 10;
    border-bottom: 25px solid $color1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    background: #FFF;
    .top {
        position: relative;
        height: 70px;
        .logo {
            position: absolute;
            top: 10px;
            left: 0px;
            height: 60px;
        }
        .search-toggle,
        .nav-toggle {
            position: absolute;
            right: -10px;
            top: 0px;
            width: 40px;
            height: 50px;
            text-align: center;
            text-decoration: none;
            color: $color1;
            &:hover {
                color: $color2;
            }
            span {
                font-size: 24px;
                line-height: 50px;
            }
        }
        .search-toggle {
            right: 30px;
            color: $color2;
        }
        .logos {
            display: none;
            a {
                .tel {
                    font-family: $font2;
                }
                .header-logos::before {
                    color: black;
                    font-size: 25px;
                }
                &.btn {
                    color: #fff;
                }
            }
        }
    }
    nav {
        position: absolute;
        left: 0px;
        top: 75px;
        width: 100%;
        background: $color1;
        display: none;
        z-index: 10;
        .container-fluid {
            padding: 0;
            ul {
                list-style: none;
                padding: 0px;
                margin: 0px;
                text-align: center;
                li {
                    border-top: 1px solid rgba(#FFF, 0.2);
                    a {
                        display: block;
                        color: #FFF;
                        text-decoration: none;
                        line-height: 30px;
                        padding: 5px 8px;
                        user-select: none;
                        &:hover {
                            background: shade($color1, 5%);
                        }
                        span {
                            display: block;
                            font-size: 12px;
                            float: right;
                            line-height: 30px !important;
                            transform: rotate(180deg);
                            transition: all 0.3s;
                            &.toggle {
                                transform: rotate(0deg);
                            }
                        }
                        &.active {
                            background: black;
                            &:hover {
                                background: shade(black, 5%);
                            }
                        }
                    }
                }
            }
            >ul {
                >li {
                    >ul {
                        background: tint($color1, 10%);
                        display: none;
                        >li {
                            a {
                                white-space: nowrap;
                                padding-left: 25px;
                                &:hover {
                                    background: tint($color1, 5%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 100px) {
    .goog-te-gadget {
        text-align: center !important;
    }
    .goog-te-gadget-simple {
        text-align: center !important;
    }
    #google_translate_element {
        padding: 10px !important;
    }
}

// *****************************************************************
// SM
// *****************************************************************
@media (min-width: $screen-sm-min) {
    header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        border-bottom: 0px;
        #google_translate_element {
            position: absolute !important;
            top: 45% !important;
            right: 3% !important;
            padding: 0 !important;
        }
        .top {
            height: 120px;
            .logo {
                position: absolute;
                top: 14px;
                left: 0px;
                height: 100px;
                z-index: 10;
            }
            .search-toggle,
            .nav-toggle {
                display: none;
            }
            .logos {
                position: absolute;
                right: 0px;
                top: 27px;
                display: block;
                a {
                    text-decoration: none;
                    color: $color1;
                    line-height: 24px;
                    margin-left: 30px;
                    span {
                        font-size: 24px;
                    }
                    &.tel {
                        color: $color1;
                        font-weight: 700;
                        font-size: 18px;
                        vertical-align: 4px;
                        span {
                            font-size: 20px;
                            vertical-align: -3px;
                        }
                    }
                }
            }
        }
        nav {
            display: block !important;
            position: static;
            .container-fluid {
                ul {
                    li {
                        border-top: none;
                        a {
                            span {
                                display: none;
                            }
                        }
                    }
                }
                >ul {
                    margin-right: 7px;
                    >li {
                        position: relative;
                        display: inline-block;
                        >a {
                            padding: 5px 8px;
                            &:hover {
                                background: black;
                            }
                        }
                        >ul {
                            position: absolute;
                            top: 100%;
                            left: 0px;
                            background: #FFF;
                            text-align: left;
                            display: block;
                            z-index: 10;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.5s;
                            border-bottom-right-radius: 3px;
                            border-bottom-left-radius: 3px;
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                            >li {
                                display: block;
                                a {
                                    color: #5b5b5b;
                                    padding: 5px 8px;
                                    &.active,
                                    &.active:hover,
                                    &:hover {
                                        background: none;
                                        color: #0C18CE;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            ul {
                                left: auto;
                                right: 0px;
                            }
                        }
                        &:hover {
                            ul {
                                max-height: 250px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************
@media (min-width: $screen-md-min) {
    header {
        #google_translate_element {
            position: absolute !important;
            top: 45% !important;
            right: 3% !important;
        }
        nav {
            .container-fluid {
                ul {
                    li {
                        a {
                            padding: 5px 15px;
                        }
                        &.waardebepaling {
                            display: none;
                        }
                    }
                }
                >ul {
                    >li {
                        >ul {
                            >li {
                                a {
                                    padding: 5px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************
@media (min-width: $screen-lg-min) {
    header {
        #google_translate_element {
            position: absolute !important;
            top: 45% !important;
            right: 13% !important;
        }
        .top {
            height: 110px;
            .logo {
                max-height: 90px;
            }
            .logos {
                top: 40px;
                a {
                    span {
                        font-size: 32px;
                    }
                    &.tel {
                        position: absolute;
                        top: 91px;
                        right: 0px;
                        color: white !important;
                        .icon-phone {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        nav {
            .container-fluid {
                ul {
                    li {
                        a {
                            padding: 20px;
                        }
                    }
                }
                >ul {
                    text-align: center;
                    margin: 0px;
                    >li {
                        >ul {
                            >li {
                                a {
                                    padding: 5px 20px;
                                }
                            }
                        }
                        &:last-child {
                            ul {
                                left: 0px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}