@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?gmgpl8');
    src: url('fonts/icomoon.eot?gmgpl8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?gmgpl8') format('truetype'),
    url('fonts/icomoon.woff?gmgpl8') format('woff'),
    url('fonts/icomoon.svg?gmgpl8#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-logo-huislijn:before {
    content: "\e902";
}

.icon-aankoopmakelaar:before {
    content: "\e90d";
}

.icon-huisverkopen:before {
    content: "\e911";
}

.icon-taxaties:before {
    content: "\e916";
}

.icon-scvm:before {
    content: "\e90c";
}

.icon-funda:before {
    content: "\e905";
}

.icon-logo-nwwi:before {
    content: "\e629";
}

.icon-logo-vastgoedcert:before {
    content: "\e62c";
}

.icon-arrow-down:before {
    content: "\e900";
}

.icon-arrow-right:before {
    content: "\e901";
}

.icon-facebook:before {
    content: "\e904";
}

.icon-googleplus:before {
    content: "\e906";
}

.icon-instagram:before {
    content: "\e907";
}

.icon-linkedin:before {
    content: "\e909";
}

.icon-map:before {
    content: "\e90a";
}

.icon-menu:before {
    content: "\e90b";
}

.icon-phone:before {
    content: "\e90e";
}

.icon-pinterest:before {
    content: "\e910";
}

.icon-search:before {
    content: "\e912";
}

.icon-search-bold:before {
    content: "\e913";
}

.icon-tiles:before {
    content: "\e914";
}

.icon-twitter:before {
    content: "\e915";
}

.icon-youtube:before {
    content: "\e918";
}

.icon-nrvt:before {
    content: "\e917";
}

.icon-erkend-taxateur:before {
    content: "\e919";
}

.icon-vbomakelaar:before {
    content: "\e90f";
}
