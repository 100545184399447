#carousel {
    .slide {
        .background {
            position: relative;
            width: 100%;
            // padding-bottom: 33.125%;
            height: 300px;
            background-size: cover;
            background-position: center center;
            .title {
                position: absolute;
                top: 50%;
                width: 90%;
                transform: translate(-50%, -50%);
                text-align: center !important;
                color: #FFF;
                h1,
                h2 {
                    color: #FFF;
                    margin: 0;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
                }
                h1 {
                    text-transform: none;
                    font-weight: 600;
                }
                h2 {
                    font-weight: 500;
                    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
                    font-size: 14px;
                }
            }
            .top-left {
                text-align: left;
                top: 10%;
                left: 5%;
                transform: none;
            }
            .top-center {
                top: 10%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            .top-right {
                text-align: right;
                top: 10%;
                right: 5%;
                transform: none;
            }
            .middle-left {
                text-align: left;
                top: 50%;
                left: 5%;
                transform: translate(0, -50%);
            }
            .middle-center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .middle-right {
                text-align: right;
                top: 50%;
                right: 5%;
                transform: translate(0, -50%);
            }
            .bottom-left {
                text-align: left;
                top: 75%;
                left: 5%;
                transform: none;
            }
            .bottom-center {
                top: 75%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            .bottom-right {
                text-align: right;
                top: 75%;
                right: 5%;
                transform: none;
            }
        }
    }
    .slick-dots {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        text-align: center;
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: rgba(#FFF, 0.5);
            margin: 0px 3px 5px 3px;
            &.slick-active {
                background: #FFF;
            }
            button {
                display: none;
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
    #carousel {
        .slide {
            .background {
                .title {
                    h1,
                    h2 {
                        font-size: 32px;
                    }
                    h2 {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
    #carousel {
        .slide {
            .background {
                .title {
                    h1,
                    h2 {
                        font-size: 38px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
    #carousel {
        .slide {
            .background {
                .title {
                    h1,
                    h2 {
                        font-size: 48px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}